<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div v-if="!editDialog" class="new-company">
        <h5>Добавить компанию</h5>
        <EditCompany
          :countries="countries"
          :companies="companies"
          :btnCancel="btnCancel"
          @getCompanies="getCompanies"
        />
      </div>
      <div v-if="editDialog" class="edit-company">
        <h5>Редактировать компанию</h5>
        <EditCompany
          :countries="countries"
          :companiesEdit="companiesEdit"
          :btnCancel="btnCancel"
          :itemId="itemId"
          @cancelEdit="cancelEdit"
          @getCompanies="getCompanies"
        />
      </div>
      <hr />
      <div v-if="companies.length" class="my-company">
        <h5>Мои компании</h5>
        <DataTable
          :value="companies"
          dataKey="id"
          v-model:companies="companies"
          responsiveLayout="scroll"
        >
          <Column
            header="Компания"
            field="nameRus"
            style="word-break: break-all"
          >
          </Column>
          <Column
            header="Страна"
            field="country.name"
            style="word-break: break-all"
          >
          </Column>
          <Column header="URL" field="url" style="word-break: break-all">
          </Column>
          <Column
            header="Верификация"
            field="isVerified"
            style="word-break: break-all"
          >
          </Column>
          <Column
            header="Запрос"
            field="isVerifyRequest"
            style="word-break: break-all"
          >
          </Column>
          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template style="width: 130px" #body="slot">
              <Button
                :disabled="slot.data.isVerifyRequest"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning m-1"
                @click="edit(slot.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                @click="del(slot.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-company">
        <p>You don't have any companies yet</p>
      </div>
    </div>
    <!-- Dialogs -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="itemEdit">
          Are you sure you want to delete?
        </p>
        <p class="text-center">
          <b>{{ itemEdit.nameRus }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: [],
      countries: [],
      companiesEdit: [],
      btnCancel: false,
      userId: this.$auth.$vm.state.data.type, // В целом не нужно
      editDialog: false, // открывает диалог edit
      deleteDialog: false, // открывает диалог delete
      itemEdit: '',
      itemId: '',
    }
  },
  async mounted() {
    this.getCountries()
    this.getCompanies()
    console.log('Manager', this.$auth.check('manager'))
  },
  methods: {
    async getCountries() {
      const resp = await this.axios.get('api/countries?page=1&itemsPerPage=30&pagination=false')
      this.countries = resp.data
    },
    async getCompanies() {
      this.btnCancel = false
      const resp = await this.axios.get('api/companies?page=1&itemsPerPage=30')
      this.companies = resp.data
      console.log('companies-u', resp.data)
    },
    edit(value) {
      this.btnCancel = true
      this.companiesEdit = value
      this.itemId = value.id
      this.editDialog = true
    },
    cancelEdit() {
      this.btnCancel = false
      this.editDialog = false
      this.getCompanies()
    },
    del(value) {
      console.log(value) // этот объект
      this.itemEdit = value
      this.deleteDialog = true
    },
    async deleteConfirm() {
      const id = this.itemEdit.id
      const headers = {
        accept: '*/*',
      }
      try {
        const resp = await this.axios.delete(`api/companies/${id}`, headers)
        this.deleteDialog = false
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Company deleted',
          group: 'info',
          life: 3000,
        })
        console.log('resp', resp)
        this.getCompanies()
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Company not delete',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped></style>
